<template>
    <div class="uk-container uk-container-expand uk-padding-small card-scrollable">
        <template v-if="internetConnection">
            <loader-page v-if="isLoading"></loader-page>
            <template v-else>
                <!-- Header -->
                <div class="uk-grid-small" uk-grid>
                    <div class="uk-width-1-5">
                        <form @submit.prevent="filterPlatformVersion({ platformCategoryId: null })">
                            <div class="uk-inline">
                                <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: search"></span>
                                <input 
                                    class="uk-input uk-width-1-1" 
                                    type="text"
                                    placeholder="Search version"
                                    v-model="meta.version"
                                >
                            </div>
                        </form>
                    </div>
                    <div class="uk-width-1-5"></div>
                    <div class="uk-width-1-5"></div>
                    <div class="uk-width-1-5"></div>
                    <div class="uk-width-1-5">
                        <button
                            v-if="consist(user_cred.roles, ['qwe123', 'superadmin-product', 'superadmin-tech'])"
                            type="button" 
                            class="uk-button uk-button-primary uk-border-rounded uk-width-1-1"
                            @click="
                                showCreateOrEditPlatformVerionModalBox({ 
                                    actionType: 'create', 
                                    platformVersionId: null,
                                    version: null, 
                                    description: null 
                                })
                            "
                        >Create</button>
                    </div>
                </div>
                <!-- End header -->

                <!-- Platform category filter -->
                <div v-if="platformCategories.length > 0" class="tab-menu">
                    <button 
                        v-for="(category, index) in platformCategories" 
                        :key="index" 
                        type="button"
                        class="tab-menu__button"
                        :class="[$route.query.tab === category.name ? 'tab-menu__button-active' : '']"
                        @click="changeActiveTabMenu({ platformCategoryData: category || null })"
                    >{{ category.name || '-' }}</button>
                </div>
                <div v-else class="uk-margin-top">
                    <p>No category platform data</p>
                </div>
                <!-- End platform category filter -->

                <!-- Platform version list -->
                <div class="uk-card uk-card-default uk-margin-top">
                    <div class="uk-overflow-auto">
                        <table class="uk-table uk-table-middle uk-table-medium uk-table-striped">
                            <thead>
                                <tr>
                                    <th class="uk-text-nowrap uk-text-center">Platform</th>
                                    <th class="uk-text-nowrap uk-text-center">Version</th>
                                    <th class="uk-text-center uk-text-break">Information</th>
                                    <th class="uk-text-nowrap uk-text-center">Created Date</th>
                                    <th class="uk-text-nowrap uk-text-center">Updated Date</th>
                                    <th 
                                        v-if="consist(user_cred.roles, ['qwe123', 'superadmin-product', 'superadmin-tech'])" 
                                        class="uk-width-small uk-text-center"
                                    >Action</th>
                                </tr>
                            </thead>
                            <loader-table v-if="tableLoading" :colspan="6"></loader-table>
                            <template v-else>
                                <tbody v-if="platformVersion.docs.length > 0">
                                    <tr
                                        v-for="(platform, index) in platformVersion.docs"
                                        :key="index"
                                    >
                                        <td class="uk-text-nowrap">{{ platform.category_name || '-' }}</td>
                                        <td class="uk-text-nowrap">{{ platform.version || '-' }}</td>
                                        <td class="uk-text-break">{{ preview(removeHtmlTag({ string: platform.description })) }}</td>
                                        <td class="uk-text-nowrap uk-text-center">{{ dateFormatter({ date: platform.created_at }) }}</td>
                                        <td class="uk-text-nowrap uk-text-center">{{ isUpdated({ createdAt: platform.created_at, updatedAt: platform.updated_at }) }}</td>
                                        <td 
                                            v-if="consist(user_cred.roles, ['qwe123', 'superadmin-product', 'superadmin-tech'])"
                                            class="uk-width-small uk-text-center"
                                        >
                                            <button 
                                                type="button" 
                                                class="uk-button uk-button-small uk-button-default"
                                            >Actions</button>
                                            <div uk-dropdown="mode: click">
                                                <ul class="uk-nav uk-dropdown-nav uk-text-left">
                                                    <li>
                                                        <a 
                                                            href="#"
                                                            @click="
                                                                showCreateOrEditPlatformVerionModalBox({
                                                                    actionType: 'edit', 
                                                                    platformVersionId: platform._id,
                                                                    version: platform.version, 
                                                                    description: platform.description 
                                                                })
                                                            "
                                                        >Edit</a>
                                                    </li>
                                                    <li class="uk-nav-divider"></li>
                                                    <li>
                                                        <a 
                                                            href="#"
                                                            @click="
                                                                showConfirmationDeletePlatformVersionModalBox({
                                                                    actionType: 'delete',
                                                                    platformVersionId: platform._id, 
                                                                    platformVersion: platform.version 
                                                                })
                                                            "
                                                        >Delete</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                <empty-table v-else :colspan="6"></empty-table>
                            </template>
                        </table>
                    </div>
                    <pagination
                        :total-data="platformVersion.totalDocs"
                        :limit="meta.limit"
                        :change-limit="changeLimit"
                        :change-page="changePage"
                    ></pagination>
                </div>
                <!-- End platform version list -->
            </template>

            <!-- Create or edit platform version modal box -->
            <div id="create-or-edit-platform-version-modal-box" class="uk-flex-top create-or-edit-platform-version-modal-box" uk-modal esc-close="false" bg-close="false">
                <div class="uk-modal-dialog uk-margin-auto-vertical">
                    <button class="uk-modal-close-default" type="button" @click="resetSelectedPlatformVersionData" uk-close></button>
                    <div class="uk-modal-header">
                        <h2 class="uk-modal-title">
                            {{ actionType === 'create' ? 'Create Form' : 'Edit Form' }}
                        </h2>
                    </div>
                    <form @submit.prevent="createEditOrDeletePlatformVersion">
                        <div class="uk-padding-small uk-grid-small" uk-grid>
                            <div class="uk-width-1-1">
                                <label for="platform" class="uk-form-label">Platform <span class="uk-text-danger">*</span></label>
                                <input 
                                    id="platform"
                                    type="text"
                                    class="uk-input uk-border-rounded"
                                    disabled
                                    v-model="selectedPlatformVersion.platformCategoryName"
                                    data-vv-name="platform"
                                    data-vv-scope="selectedPlatformVersion"
                                    v-validate="{
                                        required: true
                                    }"
                                >
                                <span class="uk-text-small uk-text-danger" v-show="errors.has('selectedPlatformVersion.platform')">{{ errors.first('selectedPlatformVersion.platform') }}</span>
                            </div>
                            <div class="uk-width-1-1">
                                <label for="version" class="uk-form-label">Version <span class="uk-text-danger">*</span></label>
                                <input 
                                    id="version"
                                    type="text"
                                    class="uk-input uk-border-rounded"
                                    placeholder="1.x.x"
                                    v-model="selectedPlatformVersion.version"
                                    data-vv-name="version"
                                    data-vv-scope="selectedPlatformVersion"
                                    v-validate="{
                                        required: true,
                                        regex: /^(\d+\.)(\d+\.)(\d+)$/
                                    }"
                                >
                                <span class="uk-text-small uk-text-danger" v-show="errors.has('selectedPlatformVersion.version')">{{ errors.first('selectedPlatformVersion.version') }}</span>
                            </div>
                            <div class="uk-width-1-1">
                                <label for="information" class="uk-form-label">Information <span class="uk-text-danger">*</span></label>
                                <vue-editor
                                    id="information"
                                    :editorToolbar="customToolbar"
                                    v-model="selectedPlatformVersion.description"
                                    data-vv-name="information"
                                    data-vv-scope="selectedPlatformVersion"
                                    v-validate="{
                                        required: true
                                    }"
                                ></vue-editor>
                                <span class="uk-text-small uk-text-danger" v-show="errors.has('selectedPlatformVersion.information')">{{ errors.first('selectedPlatformVersion.information') }}</span>
                            </div>
                        </div>
                        <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
                            <button
                                v-if="buttonLoading"
                                type="button"
                                class="uk-button uk-button-default"
                                disabled
                            >
                                <span uk-spinner="ratio: 0.5"></span>
                                <span class="uk-margin-small-left">Loading</span>
                            </button>
                            <button 
                                v-else
                                type="submit" 
                                class="uk-button"
                                :class="[disabledButtonSave ? 'uk-button-default' : 'uk-button-primary']"
                                :disabled="disabledButtonSave"
                            >Save</button>
                        </div>
                    </form>
                </div>
            </div>
            <!-- End create or edit platform version modal box -->

            <!-- Confirmation delete platform version modal box -->
            <div id="confirmation-delete-platform-version-modal-box" class="uk-flex-top confirmation-delete-platform-version-modal-box" uk-modal esc-close="false" bg-close="false">
                <div class="uk-modal-dialog uk-margin-auto-vertical">
                    <button class="uk-modal-close-default" type="button" @click="resetSelectedPlatformVersionData" uk-close></button>
                    <div class="uk-modal-header">
                        <h2 class="uk-modal-title">Are you sure to delete this version?</h2>
                    </div>
                    <div class="uk-modal-body">
                        <div class="uk-grid-small" uk-grid>
                            <div class="uk-width-1-1">
                                <div class="uk-grid-small" uk-grid>
                                    <div class="uk-width-1-4">
                                        <p class="uk-text-bold">Platform: </p>
                                    </div>
                                    <div class="uk-width-3-4 uk-text-break">
                                        <p>{{ selectedPlatformVersion.platformCategoryName || '-' }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="uk-width-1-1">
                                <div class="uk-grid-small" uk-grid>
                                    <div class="uk-width-1-4">
                                        <p class="uk-text-bold">Version: </p>
                                    </div>
                                    <div class="uk-width-3-4 uk-text-break">
                                        <p>{{ selectedPlatformVersion.version || '-' }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="uk-modal-footer uk-flex uk-flex-middle uk-flex-right">
                        <button
                            v-if="buttonLoading"
                            type="button"
                            class="uk-button uk-button-default"
                            disabled
                        >
                            <span uk-spinner="ratio: 0.5"></span>
                            <span class="uk-margin-small-left">Loading</span>
                        </button>
                        <button 
                            v-else
                            type="button" 
                            class="uk-button"
                            :class="[disabledButtonDelete ? 'uk-button-default' : 'uk-button-danger']"
                            :disabled="disabledButtonDelete"
                            @click="createEditOrDeletePlatformVersion"
                        >Delete</button>
                    </div>
                </div>
            </div>
            <!-- End confirmation delete platform version modal box -->
        </template>
        <no-internet-connection v-else></no-internet-connection>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import {
    notificationSuccess,
    notificationDanger
} from '@/utils/notification';
import { VueEditor } from "vue2-editor";
import formatter from '@/utils/formatter';
import { getUserLogin } from '@/utils/auth';

export default {
    name: 'PlatformVersion',
    components: {
        NoInternetConnection: () => import('@/components/globals/NoInternetConnection'),
        LoaderPage: () => import('@/components/globals/LoaderPage'),
        LoaderTable: () => import('@/components/globals/tables/Loading'),
        Pagination: () => import('@/components/globals/Pagination'),
        EmptyTable: () => import('@/components/globals/tables/EmptyTable'),
        VueEditor
    },
    data() {
        return {
            internetConnection: true,
            user_cred: getUserLogin(),
            isLoading: false,
            tableLoading: false,
            buttonLoading: false,
            platformCategories: [],
            platformVersion: {
                docs: [],
                meta: null,
                totalDocs: 0
            },
            meta: {
                limit: 10,
                page: 1,
                platform_category_id: null,
                version: ''
            },
            actionType: 'create',
            selectedPlatformVersion: {
                id: null,
                platformCategoryId: null,
                platformCategoryName: '',
                version: '',
                description: ''
            },
            customToolbar: [
                [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                ["bold", "italic", "underline"],
                [{ list: "bullet" }],
                [{ 'align': ''},{ 'align': 'justify'}, { 'align': 'right' }],
            ]
        };
    },
    watch: {
        async meta() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true; 
                    this.tableLoading = true;
                    await this.setPlatformVersionData();
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.tableLoading = false;
            }
        }
    },
    computed: {
        disabledButtonSave() {
            let disabled = true;
            if (this.actionType === 'create') {
                if (
                    this.selectedPlatformVersion.platformCategoryId === null || 
                    this.selectedPlatformVersion.platformCategoryName === '' ||
                    this.selectedPlatformVersion.version === '' ||
                    this.selectedPlatformVersion.description === '' ||
                    !this.checkIsEmpty({ text: this.selectedPlatformVersion.version }) ||
                    !this.checkIsEmpty({ text: this.removeHtmlTag({ string: this.selectedPlatformVersion.description }) }) ||
                    this.$validator.errors.any('selectedPlatformVersion')
                ) {
                    disabled = true;
                } else {
                    disabled = false;
                }
            } else if (this.actionType === 'edit') {
                if (
                    this.selectedPlatformVersion.id === null ||
                    this.selectedPlatformVersion.platformCategoryId === null || 
                    this.selectedPlatformVersion.platformCategoryName === '' ||
                    this.selectedPlatformVersion.version === '' ||
                    this.selectedPlatformVersion.description === '' ||
                    !this.checkIsEmpty({ text: this.selectedPlatformVersion.version }) ||
                    !this.checkIsEmpty({ text: this.removeHtmlTag({ string: this.selectedPlatformVersion.description }) }) ||
                    this.$validator.errors.any('selectedPlatformVersion')
                ) {
                    disabled = true;
                } else {
                    disabled = false;
                } 
            }

            return disabled;
        },
        disabledButtonDelete() {
            let disabled = true;

            if (
                this.selectedPlatformVersion.id === null ||
                this.selectedPlatformVersion.platformCategoryName === '' ||
                this.selectedPlatformVersion.version === ''
            ) {
                disabled = true;
            } else {
                disabled = false;
            }

            return disabled;
        }
    },
    beforeMount() {
        if (window.UIkit.modal('.create-or-edit-platform-version-modal-box')) window.UIkit.modal('.create-or-edit-platform-version-modal-box').$destroy(true);
        if (window.UIkit.modal('.confirmation-delete-platform-version-modal-box')) window.UIkit.modal('.confirmation-delete-platform-version-modal-box').$destroy(true);
    },
    async mounted() {
        try {
            if (navigator.onLine) { // Check internet connection
                this.internetConnection = true;
                this.isLoading = true;
                await this.setPlatformCategoryData();
                await this.setPlatformVersionData();
                await this.setFirstActiveTabMenu();
            } else {
                this.internetConnection = false;
            } 
        } catch (error) {
            notificationDanger(error);
        } finally {
            this.isLoading = false;
        }
    },
    methods: {
        ...mapActions({
            getAllPlatformCategory: 'platform/getAllPlatformCategory',
            getPaginateListPlatformVersion: 'platform/getPaginateListPlatformVersion',
            createPlatformVersion: 'platform/createPlatformVersion',
            editPlatformVersion: 'platform/editPlatformVersion',
            deletePlatformVersion: 'platform/deletePlatformVersion',
            getLatestVersion: 'platform/getLatestVersion'
        }),
        async setPlatformCategoryData() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    const response = await this.getAllPlatformCategory();
                    if (response && response.status === 'OK') {
                        this.platformCategories = response.result;
                    }
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {   
                notificationDanger(error);
            }
        },
        async setPlatformVersionData() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    const response = await this.getPaginateListPlatformVersion(this.meta);
                    if (response && response.status === 'OK') {
                        this.platformVersion = response.result;
                    }
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async setLatestPlatformVersionData() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    const meta = { platform_category_name: 'mabes' };
                    await this.getLatestVersion(meta);
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async filterPlatformVersion({ platformCategoryId }) {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    this.tableLoading = true;

                    this.meta.limit = 10;
                    this.meta.page = 1;
                    if (platformCategoryId) {
                        this.meta.platform_category_id = platformCategoryId;
                        this.meta.version = '';
                    }
                    await this.setPlatformVersionData();
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.tableLoading = false;
            }
        },
        async setFirstActiveTabMenu() {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    let platformCategoryId = null;

                    if (this.platformCategories.length > 0) {
                        if (!this.$route.query.tab) {
                            const platformCategoryData = this.platformCategories[0];
                            await this.setQueryUrl({ tab: platformCategoryData.name });
                            platformCategoryId = platformCategoryData._id;
                            this.selectedPlatformVersion.platformCategoryId = platformCategoryData._id;
                            this.selectedPlatformVersion.platformCategoryName = platformCategoryData.name;
                        } else {
                            const findPlatformCategory = this.platformCategories.find((category) => (
                                this.removeSpaceFromString({ string: this.$route.query.tab }).toLowerCase() === this.removeSpaceFromString({ string: category.name }).toLowerCase()
                            ));
                            if (findPlatformCategory.name !== this.$route.query.tab) {
                                await this.setQueryUrl({ tab: findPlatformCategory.name });
                            }
                            platformCategoryId = findPlatformCategory._id;
                            this.selectedPlatformVersion.platformCategoryId = findPlatformCategory._id;
                            this.selectedPlatformVersion.platformCategoryName = findPlatformCategory.name;
                        }
                    }

                    this.meta.limit = 10;
                    this.meta.page = 1;
                    await this.filterPlatformVersion({ platformCategoryId: platformCategoryId });
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async changeActiveTabMenu({ platformCategoryData }) {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    if (platformCategoryData.name !== this.$route.query.tab) {
                        await this.setQueryUrl({ tab: platformCategoryData.name });
                    }

                    this.selectedPlatformVersion.platformCategoryId = platformCategoryData._id;
                    this.selectedPlatformVersion.platformCategoryName = platformCategoryData.name;

                    this.meta.limit = 10;
                    this.meta.page = 1;
                    await this.filterPlatformVersion({ platformCategoryId: platformCategoryData._id || null });
                } else {
                    this.internetConnection = false;
                } 
            } catch (error) {
                notificationDanger(error);
            }
        },
        async setQueryUrl({ tab }) {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    await this.$router.replace({
                        path: '/admin/platform-version',
                        query: {
                            tab
                        }
                    });
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async showCreateOrEditPlatformVerionModalBox({ actionType, platformVersionId, version, description }) {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    this.hideDropdown();
                    this.actionType = actionType;
                    this.resetSelectedPlatformVersionData();
                    if (actionType === 'edit') {
                        this.fillSelectedPlatformVersionData({
                            id: platformVersionId,
                            version,
                            description
                        });
                    }
                    await window.UIkit.modal('#create-or-edit-platform-version-modal-box').show();
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async showConfirmationDeletePlatformVersionModalBox({ actionType, platformVersionId, platformVersion }) {
            try {
                if (navigator.onLine) { // Check internet connection
                    this.internetConnection = true;
                    this.hideDropdown();
                    this.actionType = actionType;
                    this.resetSelectedPlatformVersionData();
                    this.fillSelectedPlatformVersionData({ id: platformVersionId, version: platformVersion, description: null });
                    await window.UIkit.modal('#confirmation-delete-platform-version-modal-box').show();
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        hideDropdown() {
            if (navigator.onLine) { // Check internet connection
                this.internetConnection = true;
                const closeDropdown = window.UIkit.dropdown('.uk-dropdown', {
                    delayHide: 0
                });
                closeDropdown ? closeDropdown.hide() : null;
            } else {
                this.internetConnection = false;
            }
        },
        async createEditOrDeletePlatformVersion() {
            try {
                if (navigator.onLine) { // Check internet connection 
                    this.internetConnection = true;
                    if (this.actionType === 'create') {
                        await this.doCreatePlatformVersion();
                    } else if (this.actionType === 'edit') {
                        await this.doEditPlatformVersion();
                    } else if (this.actionType === 'delete') {
                        await this.doDeletePlatformVersion();
                    }
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async doCreatePlatformVersion() {
            try {
                if (navigator.onLine) { // Check internet connection 
                    this.internetConnection = true;

                    const validate = await this.$validator.validateAll('selectedPlatformVersion');
                    if (!validate || this.$validator.errors.any('selectedPlatformVersion')) return;

                    this.buttonLoading = true;

                    const payloads = {
                        platform_category_id: this.selectedPlatformVersion.platformCategoryId,
                        version: this.selectedPlatformVersion.version,
                        description: this.selectedPlatformVersion.description
                    };
                    const response = await this.createPlatformVersion(payloads);
                    if (response && response.status === 'OK') {
                        this.resetSelectedPlatformVersionData();
                        await window.UIkit.modal('#create-or-edit-platform-version-modal-box').hide();
                        notificationSuccess('Platform version created successfully');
                        this.tableLoading = true;
                        await this.setPlatformVersionData();
                        await this.setLatestPlatformVersionData();
                    }
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.buttonLoading = false;
                this.tableLoading = false;
            }
        },
        async doEditPlatformVersion() {
            try {
                if (navigator.onLine) { // Check internet connection 
                    this.internetConnection = true;

                    const validate = await this.$validator.validateAll('selectedPlatformVersion');
                    if (!validate || this.$validator.errors.any('selectedPlatformVersion')) return;

                    this.buttonLoading = true;

                    const payloads = {
                        id: this.selectedPlatformVersion.id,
                        platform_category_id: this.selectedPlatformVersion.platformCategoryId,
                        version: this.selectedPlatformVersion.version,
                        description: this.selectedPlatformVersion.description
                    };
                    const response = await this.editPlatformVersion(payloads);
                    if (response && response.status === 'OK') {
                        this.resetSelectedPlatformVersionData();
                        await window.UIkit.modal('#create-or-edit-platform-version-modal-box').hide();
                        notificationSuccess('Platform version updated successfully');
                        this.tableLoading = true;
                        await this.setPlatformVersionData();
                        await this.setLatestPlatformVersionData();
                    }
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.buttonLoading = false;
                this.tableLoading = false;
            }
        },
        async doDeletePlatformVersion() {
            try {
                if (navigator.onLine) { // Check internet connection 
                    this.internetConnection = true;

                    this.buttonLoading = true;

                    const response = await this.deletePlatformVersion(this.selectedPlatformVersion.id);
                    if (response && response.status === 'OK') {
                        this.resetSelectedPlatformVersionData();
                        await window.UIkit.modal('#confirmation-delete-platform-version-modal-box').hide();
                        notificationSuccess('Platform version deleted successfully');
                        this.tableLoading = true;
                        await this.setPlatformVersionData();
                        await this.setLatestPlatformVersionData();
                    }
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.buttonLoading = false;
                this.tableLoading = false;
            }
        },
        fillSelectedPlatformVersionData({ id, version, description  }) {
            if (navigator.onLine) { // Check internet connection
                this.internetConnection = true;
                this.selectedPlatformVersion.id = id;
                this.selectedPlatformVersion.version = version;
                this.selectedPlatformVersion.description = description;
            } else {
                this.internetConnection = false;
            }
        },
        resetSelectedPlatformVersionData() {
            if (navigator.onLine) { // Check internet connection
                this.internetConnection = true;
                this.selectedPlatformVersion.id = null;
                this.selectedPlatformVersion.version = '';
                this.selectedPlatformVersion.description = '';
                this.$validator.reset();
            } else {
                this.internetConnection = false;
            }
        },
        dateFormatter({ date }) {
            if (date) {
                return formatter.dateComplete(date);
            } else {
                return '-';
            }
        },
        isUpdated({ createdAt, updatedAt }) {
            if (!formatter.isSameDate(updatedAt, createdAt)) {
                return formatter.dateComplete(updatedAt);
            } else {
                return '-';
            }
        },
        removeSpaceFromString({ string }) {
            return string.replace(/\s/g, '');
        },
        checkIsEmpty({ text }) {
            return (/([^\s])/).test(text);
        },
        removeHtmlTag({ string }) {
            if (string) {
                return formatter.replaceHtmlTag(string);
            } else {
                return '-';
            }
        },
        preview(data) {
            if (data == null) {
                return '-';
            }
            if (data.length > 150) {
                return data.substring(0, 150)+'...';
            } else {
                return data;
            }
        },
        consist(user_roles, value){
            return value.some(i => user_roles.includes(i));
        },
        changeLimit(e) {
            if (navigator.onLine) { // Check internet connection
                this.internetConnection = true;
                this.meta = {
                    ...this.meta,
                    limit: e.target.value
                };
            } else {
                this.internetConnection = false;
            }
        },
        changePage(value) {
            if (navigator.onLine) { // Check internet connection
                this.internetConnection = true;
                this.meta = {
                    ...this.meta,
                    page: value
                };
            } else {
                this.internetConnection = false;
            }
        }
    }
};
</script>

<style scoped>
.tab-menu {
    margin-top: 20px;
    border-top: 1px solid #E5E5E5;
    overflow-x: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
}
.tab-menu__button {
    padding: 15px 50px;
    background: transparent;
    cursor: pointer;
    outline: none;
    border: none;
    color: #979797;
    font-size: 14px;
    white-space: nowrap;
}
.tab-menu__button:hover {
    color: #000000;
}
.tab-menu__button-active {
    color: #000000;
    border-bottom: 2px solid #0275D8;
}
</style>
